<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio post</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="post">
                    <div class="field">
                        <div class="title">titolo</div>
                        <div class="value">
                            {{ post.sw_posts_title }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormatWithMinutes(post.sw_posts_creation_date) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Creato da</div>
                        <div class="value">{{ post.users_first_name }} {{ post.users_last_name }}</div>
                    </div>
                    <div class="field">
                        <div class="title">Messaggio</div>
                        <div class="value" v-html="post.sw_posts_text"></div>
                    </div>
                </div>

                <!-- Elenco allegati -->
                <div class="allegati_title">Allegatiì</div>
                <div v-if="post.sw_posts_attachments.length === 0" class="no_allegati_container">
                    <div class="no_allegati">Nessun allegato da visualizzare</div>
                </div>
                <div v-else class="post">
                    <div v-for="(allegato, index) in JSON.parse(post.sw_posts_attachments)" :key="index" class="field">
                        <!-- {{ allegato }} -->
                        <div class="value value_articoli">
                            <span>{{ JSON.parse(allegato.movimenti_articoli_name) }}</span>
                            <span>{{ allegato.sw_posts_attachments }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

import { arrowBackOutline } from "ionicons/icons";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";

export default defineComponent({
    name: "PostDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        //console.log(props.data);
        const post = { ...props.data };

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        /**
         * Open fake modal to view selected post attachments
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);
        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        return {
            dateFormat,
            dateFormatWithMinutes,
            post,
            arrowBackOutline,
            closeModal,
            setImageUrl,
            selectedImage,
            showImgModal,
            openImgDetail,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new movimento btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.post {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.post .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}

.post .field:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
}
.post .field_motivazione {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_motivazione .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_motivazione .title {
    margin-bottom: 8px;
}
.field .value,
.field_motivazione .value {
    font-size: 14px;
}
.field .value.value_articoli {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.value .badge_success {
    background-color: #22c55e;
}
.value .badge_warning {
    background-color: #f97316;
}
.value .badge_danger {
    background-color: #ef4444;
}

ion-button {
    --color: #ffffff;
}

.allegati_title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 12px;
}
.no_allegati_container {
    width: 100%;
    padding-top: 8px;
}
.no_allegati {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
</style>
