import axios from "axios";

export default {
    async getPosts() {
        const response = await axios.get("rest/v1/search/sw_posts", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        console.log(response);
        return response;
    },
};
